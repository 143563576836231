import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Image from "../../../components/image";
import Text from "../../../components/text";
import { sideMenu } from "../../../config/sideMenu";
import logoWhite from "../../../assets/images/icons/logo-white.svg";
import oasisLogo from "../../../assets/images/icons/oasis.svg";
import { contactList, socialMediaList } from "../../../shared/static/contact";
import styles from "./footer.module.scss";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer
      className={`flex self-stretch justify-center items-end p-[43px] md:p-5 bg-black-900_01 ${styles["footer-container"]}`}
    >
      <div className="mt-4 flex w-[67%] flex-col items-center gap-[83px] md:w-full md:gap-[62px] sm:gap-[41px]">
        <div className="flex items-start justify-between gap-5 self-stretch md:flex-col">
          <div className="flex w-[14%] flex-col items-center gap-[21px] md:w-full">
            <Image
              src={logoWhite}
              alt="footerlogo"
              className="h-[36px] w-full object-contain md:h-auto md:w-[154px]"
            />
            <Image
              src={oasisLogo}
              alt="footerlogo"
              className="h-[26px] w-full object-contain md:h-auto md:w-[80px]"
            />
          </div>
          <div className="flex w-[46%] items-start justify-between gap-5 md:w-full sm:flex-col">
            <ul className="flex flex-col items-start">
              {sideMenu.map((item) => (
                <li key={item.path}>
                  <NavLink to={item.path} className="mt-[5px]">
                    <Text
                      as="p"
                      className="text-base font-light hover:opacity-75 transition-all ease-in-out"
                    >
                      {t(item.title)}
                    </Text>
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="flex flex-col items-start gap-[11px]">
              {contactList.map((item, idx) => (
                <div
                    key={idx}
                  className="flex items-center gap-3.5 cursor-pointer hover:opacity-75 transition-all ease-in-out"
                  onClick={item.onClick}
                >
                  <Image
                    src={item.icon}
                    alt={item.title}
                    className="h-[15px] w-[15px] self-center object-contain"
                  />
                  <Text as="p" className="text-base font-normal">
                    {item.title}
                  </Text>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-1.5 flex items-center gap-4">
            {socialMediaList.map((item) => (
              <button
                key={item.label}
                className={`${styles["social-item"]} ${styles[item.label]}`}
                onClick={item.onClick}
                aria-label={item.label}
              >
                <item.icon size={18} />
              </button>
            ))}
          </div>
        </div>
        <Text as="p" className="text-base font-light">
          {t("footer.copyright")} {new Date().getFullYear()}.{" "}
          {t("footer.allRightsReserved")}
        </Text>
      </div>
    </footer>
  );
};

export default Footer;
