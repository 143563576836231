import React from "react";
import { HeadingProps } from "../../interfaces/components/heading";

const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  children,
  className = "",
  as,
  ...restProps
}) => {
  const Component = as || "h6";

  return (
    <Component className={`font-helvetica ${className}`} {...restProps}>
      {children}
    </Component>
  );
};

export default Heading;
