import React from "react";
import { ImgProps } from "../../interfaces/components/img";

const Image: React.FC<React.PropsWithChildren<ImgProps>> = ({
  className,
  src = "default.png",
  alt = "main image",
  ...restProps
}) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      {...restProps}
      loading={"lazy"}
    />
  );
};

export default Image;
