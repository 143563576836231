import config from "../../config";
import MapIcon from "../../assets/images/icons/map.svg";
import CallIcon from "../../assets/images/icons/call.svg";
import EmailIcon from "../../assets/images/icons/email.svg";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { openLink } from "../utils/helper";
/**
 * List of contact information items
 */
export const contactList = [
  {
    title: config.address,
    longTitle: config.addressLong,
    icon: MapIcon,
    buttonTitle: "contactUs.addressButton",
  },
  {
    title: config.phoneNumber,
    icon: CallIcon,
    buttonTitle: "contactUs.numberButton",
    onClick: () => openLink(`tel:${config.phoneNumber}`),
  },
  {
    title: config.phoneNumber2,
    icon: CallIcon,
    buttonTitle: "contactUs.numberButton",
    onClick: () => openLink(`tel:${config.phoneNumber2}`),
  },
  {
    title: config.email,
    icon: EmailIcon,
    buttonTitle: "contactUs.emailButton",
    onClick: () => openLink(`mailto:${config.email}`),
  },
];

export const socialMediaList = [
  {
    label: "linkedin",
    icon: FaLinkedin,
    onClick: () => openLink(config.linkedIn),
  },
  {
    label: "facebook",
    icon: FaFacebook,
    onClick: () => openLink(config.facebook),
  },
  {
    label: "instagram",
    icon: PiInstagramLogoFill,
    onClick: () => openLink(config.instagram),
  },
  {
    label: "youtube",
    icon: FaYoutube,
    onClick: () => openLink(config.youtube),
  },
];

export const generalInfoContact = [
  {
    title: config.phoneNumber3,
    icon: CallIcon,
    buttonTitle: "contactUs.numberButton",
    onClick: () => openLink(`tel:${config.phoneNumber3}`),
  },
  {
    title: config.phoneNumber,
    icon: CallIcon,
    buttonTitle: "contactUs.numberButton",
    onClick: () => openLink(`tel:${config.phoneNumber}`),
  },
  {
    title: config.phoneNumber2,
    icon: CallIcon,
    buttonTitle: "contactUs.numberButton",
    onClick: () => openLink(`tel:${config.phoneNumber2}`),
  },
  {
    title: config.email,
    icon: EmailIcon,
    buttonTitle: "contactUs.emailButton",
    onClick: () => openLink(`mailto:${config.email}`),
  },
];
