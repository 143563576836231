import React from "react";
import Heading from "../../../../components/heading";
import Text from "../../../../components/text";
import Image from "../../../../components/image";
import Result from "../../../../assets/images/home/result.webp";
import { useTranslation } from "react-i18next";

const BusinessSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Heading
        as={"h2"}
        className={
          "my-[30px] text-[40px] whitespace-pre-wrap md:whitespace-normal text-center font-bold text-white-A700 md:text-[38px] sm:text-4xl"
        }
      >
        {t("home.businessSuccess.heading")}
      </Heading>
      <Text
        as={"p"}
        size={"xl"}
        className={
          "font-bold text-white-A700 text-center text-[24px] md:text-[22px] sm:text-[16px] mt-[60px]"
        }
      >
        {t("home.businessSuccess.empower")}
      </Text>
      <Text
        as={"p"}
        size={"xxl"}
        className={
          "text-white-A700 font-light text-center text-[15px] md:text-[14px] mt-[20px] px-[23%] md:px-[0px]"
        }
      >
        {t("home.businessSuccess.everyBusiness")}
      </Text>
      <Text
        as={"p"}
        size={"xl"}
        className={
          "text-white-A700 font-light text-[24px] md:text-[22px] sm:text-[16px] mt-[60px] px-[18%] md:pl-[4%] md:pr-0"
        }
      >
        {t("home.businessSuccess.everyBusinessGoal")}
      </Text>
      <div
        className={"flex justify-between items-center my-[100px] md:flex-wrap"}
      >
        <div className={"w-[40%] md:w-full"}>
          <Heading
            as="h4"
            className="text-[34px] mb-[25px] font-bold md:text-[32px] sm:text-2xl"
          >
            {t("home.businessSuccess.thisResults")}
          </Heading>
          <Text
            size="xxl"
            as="p"
            className="w-full text-xl text-white-A700 font-normal leading-[68px] md:text-[18px]"
          >
            <span className="font-bold text-white-A700">
              {t("home.businessSuccess.ineffectiveness")}
            </span>
            &nbsp;{t("home.businessSuccess.wastedResources")}
            <br />
            <span className="font-bold text-white-A700">
              {t("home.businessSuccess.disappointment")}
            </span>
            &nbsp;{t("home.businessSuccess.inabilityToAdapt")}
            <br />
            <span className="font-bold text-white-A700">
              {t("home.businessSuccess.failure")}
            </span>
            &nbsp;{t("home.businessSuccess.strayingFromPath")}
          </Text>
        </div>
        <div className={"w-[30%] md:w-full"}>
          <Image
            src={Result}
            alt={"result"}
            className={"rounded-[15px] object-cover"}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessSuccess;
