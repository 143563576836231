import { toast } from "react-toastify";
import { TypeOptions } from "react-toastify/dist/types";

/**
 * Displays a global toast notification with the specified message, type, and duration.
 *
 * @param {Object} options - The options for the toast notification.
 * @param {string} options.message - The message to be displayed in the toast.
 * @param {TypeOptions} options.type - The type of the toast (e.g., "success", "error").
 * @param {number} options.duration - The duration for which the toast should be displayed.
 */
const showGlobalToast = ({
  message,
  type,
  duration,
}: {
  message: string;
  type: TypeOptions;
  duration: number;
}) => {
  const toastId = Math.floor(Math.random() * 1000);
  toast(message, {
    position: "bottom-right",
    autoClose: duration,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type: type,
    toastId,
  });
  toast.play({ id: toastId });
};

/**
 * Displays a success toast notification with the specified message and duration.
 *
 * @param {string} message - The message to be displayed in the success toast.
 * @param {number} [duration=5000] - The duration for which the success toast should be displayed.
 */
const showSuccessToast = (message: string, duration: number = 5000) => {
  showGlobalToast({ message, type: "success", duration });
};

/**
 * Displays an error toast notification with the specified message and duration.
 *
 * @param {string} [message="Something went wrong, please try again later!"] - The message to be displayed in the error toast.
 * @param {number} [duration=5000] - The duration for which the error toast should be displayed.
 */
const showErrorToast = (
  message: string = "Something went wrong, please try again later!",
  duration: number = 5000,
) => {
  showGlobalToast({ message, type: "error", duration });
};

/**
 * Service for displaying toast notifications.
 */
export const toastService = {
  showSuccessToast,
  showErrorToast,
};
