const baseUrl = "https://flowforge.canguru.au/flowforge-backend/public/api/";

const api = {
  /**
   * The API endpoint for adding contact information.
   * @type {string}
   */
  contact: `${baseUrl}contact/add`,
};

export default api;
