import { isBrowserDefined } from "./browserService";

/**
 * Scrolls the browser window to the contact section on the page.
 * @function
 * @returns {void}
 */
export const scrollToContact = (): void => {
  if (!isBrowserDefined()) return;
  const contactSection = document.getElementById("contact");
  if (!contactSection) return;
  const rect = contactSection.getBoundingClientRect();
  window.scrollTo({
    top: window.pageYOffset + rect.top,
    behavior: "smooth",
  });
};
