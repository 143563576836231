import React, { useEffect, useRef, useState } from "react";
import Text from "../../../../components/text";
import Image from "../../../../components/image";
import LogoWhite from "../../../../assets/images/home/logoWhite.svg";
import styles from "./strategyExecutionState.module.scss";
import { useTranslation } from "react-i18next";

interface ListItem {
  title: string;
  description: string;
  width: string;
}

const StrategyExecutionStats: React.FC = () => {
  const { t } = useTranslation();

  const list: ListItem[] = [
    {
      title: "98%",
      description: t("home.strategyExecutionStats.98"),
      width: "30%",
    },
    {
      title: "74%",
      description: t("home.strategyExecutionStats.74"),
      width: "30%",
    },
    {
      title: "61%",
      description: t("home.strategyExecutionStats.61_1"),
      width: "30%",
    },
    {
      title: "61%",
      description: t("home.strategyExecutionStats.61_2"),
      width: "35%",
    },
    {
      title: "18%",
      description: t("home.strategyExecutionStats.18"),
      width: "35%",
    },
  ];

  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setVisible(true);
            observer.disconnect(); // Disconnect after the first trigger to avoid multiple re-renders
          }
        },
        { threshold: 0.5 }
    );

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
      <div
          ref={ref}
          className={`${styles["strategyExecutionState-container"]} flex items-center flex-wrap justify-between self-stretch bg-teal-300 pr-[20px] md:px-[20px] py-[103px]  md:p-5`}
      >
        <div
            className={`${styles["left-container"]} w-[35%] md:w-full md:p-[4%] md:mb-[20px] flex items-center justify-end px-[4%] py-[30px]`}
        >
          <Image
              src={LogoWhite}
              className={"w-[35%] md:w-[40%]"}
              alt={"logo white"}
          />
        </div>
        <div
            className={`${styles["right-container"]} w-[64%] md:w-full md:p-[4%]  px-[4%] py-[30px] rounded-[15px]`}
        >
          <Text as="p" size="xl" className="text-black mb-4">
            {t("home.strategyExecutionStats.intro")}
          </Text>
          <div className="flex items-start flex-wrap gap-5">
            {list.map((item, index) => (
                <div
                    key={index}
                    className={`flex flex-col items-center w-[${item.width}] md:w-[100%]`}
                >
                  <NumberAnimation
                      number={parseInt(item.title)}
                      isVisible={visible}
                  />
                  <Text
                      as="p"
                      size="lg"
                      className="text-black self-start text-[16px]"
                  >
                    {item.description}
                  </Text>
                </div>
            ))}
          </div>
        </div>
      </div>
  );
};

interface NumberAnimationProps {
  number: number;
  isVisible: boolean;
}

const NumberAnimation: React.FC<NumberAnimationProps> = ({
                                                           number,
                                                           isVisible,
                                                         }) => {
  const [displayNumber, setDisplayNumber] = useState(0);

  useEffect(() => {
    if (isVisible) {
      let start = 0;
      const end = number;
      const duration = 2000;
      const stepTime = Math.abs(Math.floor(duration / end));
      const timer = setInterval(() => {
        start += 1;
        setDisplayNumber(start);
        if (start === end) {
          clearInterval(timer);
        }
      }, stepTime);
      return () => clearInterval(timer);
    }
  }, [isVisible, number]);

  return (
      <div
          className={`${styles["number-animation"]} text-black self-start md:text-[40px] sm:text-[40px] leading-[60px] md:leading-[50px]`}
      >
        {displayNumber}%
      </div>
  );
};

export default StrategyExecutionStats;
