import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

/**
 * ScrollToTop component that scrolls the window to the top whenever the pathname changes.
 *
 * This component uses the useLocation hook from react-router-dom to detect changes in the pathname
 * and the useEffect hook to perform the scroll to the top action whenever the pathname changes.
 *
 * @returns {null} This component does not render anything.
 */
const ScrollToTop = (): null => {
  const { pathname } = useLocation();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
