import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "../../../components/heading";
import Text from "../../../components/text";
import generateMeta from "../../../shared/services/metadataService";
import metaData from "../../../config/metaData";
import Image from "../../../components/image";
import OasisPlatform from "../../../assets/images/shared/oasis-platform.webp";

const ServingYourSuccess = () => {
  const { t } = useTranslation();
  const listItems = t("servingYourSuccess.listItems", {
    returnObjects: true,
  }) as string[];

  return (
    <div>
      {generateMeta({
        title: metaData.servingYourSuccess.title,
        description: metaData.servingYourSuccess.description,
      })}
      <div className="bg-white-A700 flex flex-col justify-center items-center">
        <div className="w-[67%] sm:w-[100%] md:w-[100%] flex flex-col items-center container-xs mt-[107px] sm:mt-[40px] md:p-5">
          <div className="flex flex-col items-center text-center">
            <Heading
              as="h1"
              className="text-[40px] font-bold !text-teal-300 md:text-[38px] sm:text-4xl"
            >
              {t("servingYourSuccess.heading")}
            </Heading>
            <Text
              size="xxl"
              as="p"
              className="mt-[90px] sm:mt-[45px] font-normal !text-teal-300 text-[40px] md:text-[38px] sm:text-[20px]"
            >
              <span className="text-teal-300">
                {t("servingYourSuccess.ifYouMadeIt")}&nbsp;
              </span>
              <span className="font-bold text-teal-300">
                {t("servingYourSuccess.ready")}
              </span>
            </Text>
          </div>
          <Text
            size="xxl"
            as="p"
            className="mt-[90px] font-normal !text-teal-300 text-[40px] md:text-[24px] sm:text-[18px] leading-[50px] md:leading-[40px] sm:leading-[30px]"
          >
            {t("servingYourSuccess.introText")}
          </Text>
          <ul className="mt-[68px] w-full text-left list-disc list-outside pl-10 pr-[20px]">
            {listItems.map((item, index) => (
              <li
                key={index}
                className="font-normal !text-teal-300 text-[29px] md:text-[24px] sm:text-[18px] leading-[55px] md:leading-[40px] sm:leading-[30px]"
              >
                {item}
              </li>
            ))}
          </ul>
          <Text
            as="p"
            size="xxl"
            className="mt-[67px] mb-[103px] w-full font-normal leading-[50px] md:leading-[40px] sm:leading-[30px] !text-teal-300 text-[29px] md:text-[24px] sm:text-[18px]"
          >
            {t("servingYourSuccess.yourSuccessJourney")}
          </Text>
        </div>
      </div>
      <div className="flex flex-col items-center self-stretch bg-teal-300 px-14 pt-[98px] md:p-5">
        <div className="mb-[79px] flex w-[67%] flex-col gap-[67px] md:w-full sm:gap-[33px]">
          <div className="container-xs flex flex-col items-start gap-[61px] px-[13px] md:p-5 sm:gap-[30px]">
            <Heading
              as="h2"
              className="text-white-A700 font-bold md:ml-0 text-[36px] md:text-[32px] sm:text-[20px]"
            >
              {t("servingYourSuccess.strategyBridgeHeading")}
            </Heading>
            <Heading
              as="h3"
              className="text-[28px] font-bold md:text-[26px] sm:text-xl"
            >
              {t("servingYourSuccess.strategyBridgeSubheading")}
            </Heading>
            <Text
              size="xxl"
              as="p"
              className="text-white-A700 m-auto w-full text-[29px] md:text-[24px] sm:text-[18px] font-normal leading-[41px] md:leading-[38px] sm:leading-[30px]"
            >
              {t("servingYourSuccess.strategyBridgeText")}
            </Text>
          </div>
          <Image
            className="w-[100%] object-contain mb-[12px]"
            src={OasisPlatform}
            alt="oasis platform"
          />
          <Heading
            as="h2"
            className="text-white-A700 mt-[-21px] font-bold text-[36px] md:text-[32px] sm:text-[20px]"
          >
            {t("servingYourSuccess.platformHeading")}
          </Heading>
          <Text
            size="xxl"
            as="p"
            className="font-normal leading-[41px] md:leading-[38px] sm:leading-[30px] text-[29px] md:text-[24px] sm:text-[18px] text-white-A700"
          >
            {t("servingYourSuccess.platformText")}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ServingYourSuccess;
