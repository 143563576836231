import { isBrowserDefined } from "../services/browserService";
import parse from "node-html-parser";

/**
 * Opens a link in a new browser tab if the browser environment is defined.
 *
 * @param {string} link - The URL to be opened.
 */
export const openLink = (link: string) => {
  if (!isBrowserDefined()) return;
  window.open(link, "_blank");
};

/**
 * Extracts text content from HTML and returns an excerpt limited by word or character count.
 *
 * @param {string} html - The HTML string to extract content from.
 * @param {number} [wordLimit=50] - The maximum number of words to include in the excerpt.
 * @param {string} [tail="... (Read more)"] - The string to append at the end of the excerpt if it is truncated.
 * @param {number} [charLimit] - The maximum number of characters to include in the excerpt.
 *
 * @returns {string} The extracted text content with the specified limits and tail if truncated.
 */
export const extractContent = (
  html: string,
  wordLimit: number = 50,
  tail: string = "... (Read more)",
  charLimit?: number,
): string => {
  const root = parse(html);
  const textContent = root.textContent || ""; // Extracts text content from the parsed HTML

  let excerpt = "";
  let currentLength = 0;

  if (charLimit !== undefined && charLimit < textContent.length) {
    excerpt = textContent.slice(0, charLimit);
  } else {
    const words = textContent.split(/\s+/);
    for (let i = 0; i < words.length && i < wordLimit; i++) {
      if (
        charLimit !== undefined &&
        currentLength + words[i].length + 1 > charLimit
      ) {
        excerpt = textContent.slice(0, currentLength).trim();
        break;
      }
      excerpt += words[i] + " ";
      currentLength += words[i].length + 1; // Update length with word and a space
    }
  }
  return excerpt.length < textContent.length
    ? `${excerpt.trim()}${tail}`
    : excerpt.trim();
};

/**
 * Truncates a given text to a specified character limit and appends ellipsis if truncated.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} [limit=50] - The maximum number of characters to include in the truncated text.
 *
 * @returns {string} The truncated text with ellipsis if truncated.
 */
export const truncateText = (text: string, limit: number = 50) => {
  if (!text) return "";
  if (text.length <= limit) return text;
  return `${text.substring(0, limit)}...`;
};
