import { useState, useEffect } from "react";
import { isBrowserDefined } from "../services/browserService";

interface ScrollPosition {
  x: number;
  y: number;
  isBottom: boolean;
}

const useScrollPosition = (): ScrollPosition => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({ x: 0, y: 0, isBottom: false });

  useEffect(() => {
    if (isBrowserDefined()) {
      const handleScroll = () => {
        const isBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
        setScrollPosition({
          x: window.pageXOffset,
          y: window.pageYOffset,
          isBottom,
        });
      };
      window.addEventListener("scroll", handleScroll);
      handleScroll();
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
