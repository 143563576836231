/**
 * An object representing the paths for different screens in the application.
 *
 * @constant {Object}
 * @property {string} home - The path for the home screen.
 */
export const screens = {
  home: "/",
  generalCompanyInformation: "/general-company-info",
  servingYourSuccess: "/serving-your-success",
  ourStory: "/our-story",
  oasisPlatform: "/oasis-platform",
};
