import React from "react";
import Image from "../../../../components/image";
import Text from "../../../../components/text";
import { useTranslation } from "react-i18next";
import Logo from "../../../../assets/images/home/logo.svg";
import OasisLogo from "../../../../assets/images/home/oasisLogo.svg";

const Details: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white-A700 flex flex-col justify-center items-center">
      <div className="w-[60%] sm:w-[100%] md:w-[100%] flex flex-col items-center container-x md:p-5">
        <div className="flex flex-col mt-[60px] items-center">
          <Image
            className={
              "w-[240px] h-[190px] md:w-[40%] md:h-[auto] mb-[60px] object-contain"
            }
            src={Logo}
            alt={"FlowForge logo"}
          />
          <Text
            as={"p"}
            size={"md"}
            className={
              "text-black-900_01 text-[24px] text-center md:text-[22px] sm:text-[18px] leading-[40px] md:leading-[30px] whitespace-pre-wrap md:whitespace-normal"
            }
          >
            {t("home.details.description1")}{" "}
            <span className={"font-bold"}>OASIS™</span>{" "}
            {t("home.details.description2")}
          </Text>
          <Image
            className={
              "w-[114px] md:w-[20%] md:h-[auto] mt-[140px] mb-[30px] object-contain"
            }
            src={OasisLogo}
            alt={"Oasis logo"}
          />
          <Text
            as={"p"}
            size={"md"}
            className={
              "text-black-900_01 mb-[50px] text-[24px] text-center md:text-[22px] sm:text-[18px] leading-[40px] md:leading-[30px]"
            }
          >
            {t("home.details.description3")}
          </Text>
          <Text
            as={"p"}
            size={"md"}
            className={
              "text-black-900_01 mb-[50px] text-[30px] font-bold text-center md:text-[26px] sm:text-[20px] leading-[40px] md:leading-[30px]"
            }
          >
            {t("home.details.letTheResultsTalk")}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Details;
