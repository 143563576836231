import React from "react";
import { TextProps } from "../../interfaces/components/text";
import { textSizes } from "../../shared/enum/text";

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  as,
  size = "md",
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component
      className={`font-helvetica ${className} ${textSizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export default Text;
