import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button";
import Heading from "../../../components/heading";
import Text from "../../../components/text";
import Image from "../../../components/image";
import { generalInfoContact } from "../../../shared/static/contact";
import styles from "./generalCompanyInformation.module.scss";
import generateMeta from "../../../shared/services/metadataService";
import metaData from "../../../config/metaData";
import { scrollToContact } from "../../../shared/services/contactService";

const GeneralCompanyInformation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      className={
        "w-full px-14 bg-white flex flex-col justify-center items-center"
      }
    >
      {generateMeta({
        title: metaData.generalCompanyInformation.title,
        description: metaData.generalCompanyInformation.description,
      })}
      <Heading
        as="h1"
        className="mt-[103px] sm:mt-[40px] text-[40px] font-bold text-teal-300 md:text-[38px] sm:text-4xl sm:text-center"
      >
        {t("generalCompanyInfo.heading")}
      </Heading>
      <div className={"mt-[34px] flex h-[60px] sm:h-[40px] min-w-[300px] sm:min-w-[80%]"}>
        <Button
          onClick={() => scrollToContact()}
          buttonType={"dark"}
          label={t("generalCompanyInfo.buttonLabel")}
          className="flex-row items-center justify-center rounded-[10px] px-[35px] sm:px-[12px] text-center text-xl sm:text-[15px] font-bold shadow-sm"
        />
      </div>
      <Text
        size="xxl"
        as="p"
        className="mt-[81px] w-[34%] font-normal leading-[55px] text-teal-300 md:w-full md:p-5 text-[29px] md:text-[24px] sm:text-[18px]"
      >
        <>
          {t("generalCompanyInfo.joinTeam")}
          <br />
          {t("generalCompanyInfo.email")}
        </>
      </Text>
      <Text
        size="xxl"
        as="p"
        className="mt-[37px] mb-[36px] font-normal text-teal-300 text-[29px] md:text-[24px] sm:text-[18px]"
      >
        {t("generalCompanyInfo.contact")}
      </Text>

      {generalInfoContact.map((item) => (
        <div
          key={item.title}
          className="mt-[12px] flex items-center gap-2 sm:w-full cursor-pointer justify-between hover:opacity-75 transition-all ease-in-out"
          onClick={item.onClick}
        >
          <div className="flex items-center justify-center w-[50px] h-[50px]">
            <Image
              src={item.icon}
              alt={item.title}
              className={`h-[30px] w-[30px] object-contain ${styles["contact-icon"]}`}
            />
          </div>
          <Text
            size="xxl"
            as="p"
            className="font-normal text-teal-300 text-[24px] md:text-[22px] min-w-[300px] ml-2"
          >
            {item.title}
          </Text>
        </div>
      ))}
      <Text
        size="xxl"
        as="p"
        className="mt-[99px] w-[32%] font-normal leading-[50px] mb-8 text-teal-300 text-[29px] md:w-full md:p-5 md:text-[24px] sm:text-[18px]"
      >
        <>
          {t("generalCompanyInfo.privacyStatement")}
          <br />
          {t("generalCompanyInfo.termsConditions")}
          <br />
          {t("generalCompanyInfo.platformTermsConditions")}
        </>
      </Text>
    </div>
  );
};

export default GeneralCompanyInformation;
