import React from "react";
import Text from "../../../../components/text";
import Image from "../../../../components/image";
import { ContentSectionProps } from "../../../../interfaces/screens/oasisPlatform/contentSection";

const ContentSection: React.FC<ContentSectionProps> = ({
  text,
  boldText,
  imageSrc,
  imageAlt,
  imageWidth = "50%", // Default value is 50%
  imageFirst = false,
}) => {
  return (
    <div className="flex md:flex-wrap justify-between items-center">
      {imageFirst && (
        <div className={`w-[${imageWidth}] md:w-full`}>
          <Image
            className="w-full mt-[80px] md:h-[auto] object-contain"
            src={imageSrc}
            alt={imageAlt}
          />
        </div>
      )}
      <div className="w-[40%] md:w-full">
        {text.map((paragraph, index) => (
          <Text
            key={index}
            as="p"
            size="xxl"
            className="text-2xl md:text-xl w-[90%] mt-[60px] mb-[20px] md:mt-[10px]"
          >
            {paragraph}
          </Text>
        ))}
        <Text
          as="p"
          size="xxl"
          className="text-3xl md:text-2xl w-[90%] font-bold mt-[60px] mb-[20px] md:mt-[10px]"
        >
          {boldText}
        </Text>
      </div>
      {!imageFirst && (
        <div className={`w-[${imageWidth}] md:w-full`}>
          <Image
            className="w-full mt-[80px] md:h-[auto] object-contain"
            src={imageSrc}
            alt={imageAlt}
          />
        </div>
      )}
    </div>
  );
};

export default ContentSection;
