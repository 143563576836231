import React from "react";
import Image from "../../../../components/image";
import Text from "../../../../components/text";
import HeroImage from "../../../../assets/images/home/hero.webp";
import styles from "./hero.module.scss";
import Button from "../../../../components/button";
import { useTranslation } from "react-i18next";
import { scrollToContact } from "../../../../shared/services/contactService";

const Hero: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      className={`relative w-full h-[84vh] md:h-[90vh] ${styles["hero-container"]}`}
    >
      <Image
        src={HeroImage}
        alt={"hero"}
        className={"w-full h-full object-cover object-center"}
      />
      <div
        className={
          "absolute top-0 left-0 w-[100%] h-[100%] flex justify-center items-center"
        }
        style={{
          background:
            "linear-gradient(184.19deg, rgba(255, 255, 255, 0) 22.81%, rgba(71, 171, 195, 0.65) 40.44%, rgba(44, 107, 128, 0.65) 59.97%, rgba(26, 65, 84, 0.65) 74.35%, rgba(20, 49, 67, 0.65) 88.74%, rgba(0, 20, 29, 0.65) 101.92%)",
        }}
      >
        <div
          className={
            "flex flex-col items-center justify-center w-[45%] md:w-[100%] px-[12px]"
          }
        >
          <Text
            as={"p"}
            size={"xxl"}
            className={`text-white-A700 text-4xl md:text-2xl font-bold text-center ${styles["hero-text"]}`}
          >
            {t("home.hero.heading")}
          </Text>
        </div>
        <div className={"w-[300px] absolute bottom-[6%]"}>
          <Button
            label={t("home.hero.startJourney")}
            onClick={() => scrollToContact()}
          />
        </div>
      </div>
    </div>
  );
};
export default Hero;
