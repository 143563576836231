/**
 * Handle form changes and update the current state.
 *
 * @param {string} key - The key of the form field.
 * @param {string | number | any} value - The new value of the form field.
 * @param {object} currentForm - The current form state.
 * @param {Function} setFunction - The function to update the form state.
 */
export const handleChange = (
  key: string,
  value: string | number | any,
  currentForm: object,
  setFunction: Function,
) => {
  setFunction({
    ...currentForm,
    [key]: value,
  });
};

/**
 * Check if the form is valid.
 *
 * @param {object} form - The form object.
 * @param {string[]} mandatoryFields - The array of mandatory field keys.
 * @returns {boolean} - Returns true if the form is valid, false otherwise.
 */
export const checkIfFormValid = (
  form: any,
  mandatoryFields: string[],
): boolean => {
  let _formComplete = true;
  for (let index = 0; index < mandatoryFields.length; index++) {
    const field = mandatoryFields[index];
    if (!form[field] || form[field].length <= 0) {
      _formComplete = false;
      break;
    }
  }
  return _formComplete;
};

/**
 * Generate form data for steps.
 *
 * @param {any} form - The form data object.
 * @returns {FormData} - The generated FormData object.
 */
export const generateFormData = (form: any) => {
  const formData = new FormData();
  Object.keys(form).forEach((key) => {
    if (typeof form[key] === "object" && form[key]?.length > 0) {
      form[key].forEach((item: any) => {
        formData.append(`${key}[]`, item);
      });
    } else if (typeof form[key] === "object") {
      formData.append(key, form[key]);
    } else if (typeof form[key] === "string" && form[key]) {
      formData.append(key, form[key]);
    } else if (form[key]) {
      formData.append(key, form[key].toString());
    }
  });
  return formData;
};

/**
 * Validate email
 * @param email
 */
export const validateEmail = (email: string): boolean => {
  // Regular expression for basic email validation
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
};

/**
 * Validate phone number
 * @param phoneNumber
 */
export const validatePhoneNumber = (phoneNumber: string): boolean => {
  const pattern = /^\+?\d{8,}$/;
  return pattern.test(phoneNumber);
};
