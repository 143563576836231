import { MetaData } from "../interfaces/config/metaData";

/**
 * Default description used for meta data.
 *
 * @constant {string}
 */
const description =
  "Empowering organizations to seamlessly integrate strategy with execution, our innovative and transformative solutions address today's business complexities. Committed to collaboration and sustainable growth, we provide tailored guidance across industries, unlocking potential and driving meaningful impact.";

/**
 * Metadata for different pages in the application.
 *
 * @type {MetaData}
 */
const metaData: MetaData = {
  home: {
    title: "FlowForge Solutions | Empowering Business Innovation",
    description,
  },
  notFound: {
    title: "FlowForge Solutions | Not Found.",
    description,
  },
  generalCompanyInformation: {
    title: "FlowForge Solutions | General Company Information",
    description:
      "Discover more about FlowForge's mission, values, and the team dedicated to driving business innovation and sustainable growth. Get in touch with us to learn how we can help your organization thrive.",
  },
  servingYourSuccess: {
    title: "FlowForge Solutions | FlowForge Serving Your Success",
    description:
      "Explore how FlowForge can drive your success with proven strategies and solutions. Learn about our achievements and how we can help you achieve your goals.",
  },
  ourStory: {
    title: "FlowForge Solutions | FlowForge Our Story",
    description:
      "Discover how FlowForge Solutions is bridging the gap between strategy and execution. Learn about our innovative OASIS™ platform, our team's expertise, and our mission to transform business potential into success through AI-driven strategies.",
  },
  oasisPlatform: {
    title: "FlowForge Solutions | FlowForge Oasis Platform",
    description:
      "Discover how FlowForge Solutions is bridging the gap between strategy and execution. Learn about our innovative OASIS™ platform, our team's expertise, and our mission to transform business potential into success through AI-driven strategies.",
  },
};

export default metaData;
