import React from "react";
import generateMeta from "../../../shared/services/metadataService";
import metaData from "../../../config/metaData";
import Hero from "./hero";
import Details from "./details";
import BusinessSuccess from "./businessSuccess";
import Problem from "./problem";
import OasisSolution from "../../../components/oasisSolution";
import Button from "../../../components/button";
import { useTranslation } from "react-i18next";
import Text from "../../../components/text";
import Heading from "../../../components/heading";
import CoreServices from "./coreServices";
import StrategyExecutionStats from "./strategyExecutionStats";
import Why from "./why";
import { useNavigate } from "react-router-dom";
/**
 * Home component displays the home page.
 * @returns {JSX.Element} The Home component JSX
 */
const Home: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      {generateMeta({
        title: metaData.home.title,
        description: metaData.home.description,
      })}
      <Hero />
      <Details />
      <div className="flex flex-col items-center self-stretch bg-teal-300 px-14 pt-[40px] md:p-5">
        <div className={"w-[67%] md:w-full"}>
          <BusinessSuccess />
          <Problem />
        </div>
        <div className={"w-[84%] md:w-full"}>
          <Heading
            as="h1"
            className="my-[50px] ml-[3.3%] self-start text-4xl font-bold md:text-[44px] sm:text-[38px]"
          >
            {t("home.weHaveTheSolution")}
          </Heading>
          <OasisSolution />
          <div className="px-[15%] mt-[70px] flex flex-col items-center gap-[79px] md:gap-[59px] md:p-5 sm:gap-[39px]">
            <Text
              size="xxl"
              as="p"
              className="w-full text-[24px] font-normal leading-8 md:text-[18px] sm:text-md"
            >
              {t("home.noMoreConsultants")}
            </Text>
            <Text
              size="xxl"
              as="p"
              className="text-[24px] text-white-A700 font-normal md:text-[18px] sm:text-md"
            >
              {t("home.oasisReplacesAll")}
            </Text>
            <div className={"w-[24%] md:w-[35%] sm:w-[50%]"}>
              <Button
                label={t("home.discoverOasis")}
                onClick={() => navigate("/oasis-platform")}
                buttonType={"dark"}
              />
            </div>
          </div>
        </div>
        <div className={"w-67% md:w-full"}>
          <CoreServices />
        </div>
      </div>
      <StrategyExecutionStats />
      <Why />
    </div>
  );
};

export default Home;
