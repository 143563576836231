import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../screens/content/home";
import NotFound from "../screens/content/notFound";
import ScrollToTop from "./scrollToTop";
import { isBrowserDefined } from "../shared/services/browserService";
import CustomLayout from "../screens/customLayout";
import "../translation/index";
import { screens } from "../config/routes";
import { store } from "../redux/store";
import { Provider } from "react-redux";
import GeneralComanyInformation from "../screens/content/generalComanyInformation";
import ServingYourSuccess from "../screens/content/servingYourSuccess";
import OurStory from "../screens/content/ourStory";
import OasisPlatform from "../screens/content/oasisPlatform";

/**
 * MainRouter component that handles the routing for the application.
 *
 * This component sets up the routes and ensures that the scroll restoration behavior is set to manual
 * when the application is running in a browser environment. It also wraps the routes with a Redux Provider
 * and a custom layout.
 *
 * @returns {JSX.Element} The MainRouter component.
 */
const MainRouter = (): JSX.Element => {
  useEffect(() => {
    if (isBrowserDefined()) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  return (
    <Provider store={store}>
      <CustomLayout>
        {isBrowserDefined() && <ScrollToTop />}
        <Routes>
          <Route path={screens.home} element={<Home />} />
          <Route path="/home" element={<Navigate to="/" />} />
          <Route
            path={screens.generalCompanyInformation}
            element={<GeneralComanyInformation />}
          />
          <Route
            path={screens.servingYourSuccess}
            element={<ServingYourSuccess />}
          />
          <Route path={screens.ourStory} element={<OurStory />} />
          <Route path={screens.oasisPlatform} element={<OasisPlatform />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </CustomLayout>
    </Provider>
  );
};

export default MainRouter;
