import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button";
import logo from "../../../assets/images/icons/logo.svg";
import {desktopSideMenu, sideMenu} from "../../../config/sideMenu";
import Image from "../../../components/image";
import Text from "../../../components/text";
import backgroundImage from "../../../assets/images/header/background.png";
import { scrollToContact } from "../../../shared/services/contactService";
import {IoClose} from "react-icons/io5";
import {HiOutlineMenuAlt1} from "react-icons/hi";

const Header: React.FC = () => {
  const { t } = useTranslation();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    const newState = !isSideMenuOpen;
    setIsSideMenuOpen(newState);
  }, [isSideMenuOpen]);

  return (
    <header className={`w-full bg-white-A700_cc`}>
      <div
        className="flex h-[99px] items-end self-stretch bg-cover bg-no-repeat p-[22px] md:h-auto sm:p-5 justify-between"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <NavLink to={"/"}>
          <div className="flex items-center">
            <Image
              src={logo}
              alt="headerlogo"
              className="ml-16 mt-1.5 h-[47px] w-[200px] object-contain md:ml-0"
            />
            <Text
              size="xs"
              as="p"
              className="sm:hidden smTablet:hidden mb-3.5 self-end text-sm font-normal !text-black-900_01"
            >
              {t("header.drivingFutureSuccessToday")}
            </Text>
          </div>
        </NavLink>
        <div className="sm:hidden flex h-[48px] min-w-[208px] items-center justify-center">
          <Button
            buttonType={"dark"}
            label={t("header.startYourJourney")}
            className="rounded-[10px] px-[22px] text-center text-lg font-bold text-white-A700 shadow-sm"
            onClick={() => scrollToContact()}
          />
        </div>
        <button
          className="hidden sm:block ml-auto"
          onClick={toggleMenu}
          aria-label={t("header.toggleSideMenu")}
        >
            <HiOutlineMenuAlt1 size={40} color={'#000'}/>
        </button>
      </div>
      <div
        className="sm:hidden p-2.5 pl-24 flex self-stretch bg-gradient-to-r from-[#47ABC3] via-transparent to-transparent filter drop-shadow-lg backdrop-blur-md"
        style={{
          background: `radial-gradient(235.74% 806.57% at 0% 0%, #47ABC3 10%, rgba(71, 171, 195, 0) 100%)`,
        }}
      >
        <ul className="ml-20 flex flex-wrap gap-20 md:ml-0 md:gap-5">
          {desktopSideMenu.map((item) => (
            <li key={item.path}>
              <NavLink to={item.path}>
                <Text
                  size={"sm"}
                  as="p"
                  className="text-sm font-normal hover:opacity-75 transition-all ease-in-out"
                >
                  {t(item.title)}
                </Text>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
        <div
            className={`fixed inset-0 z-50 bg-gray-800 bg-opacity-75 transition-transform transform ${isSideMenuOpen ? "translate-x-0" : "translate-x-full"} lg:hidden`}
        >
            <div
                className="fixed top-0 right-0 h-full w-full bg-white-400 shadow-lg p-5 flex flex-col items-center justify-center background-gradient"
                style={{
                    backdropFilter: "blur(20px)",
                }}
            >
                {sideMenu.map((item) => (
                    <NavLink
                        key={item.path}
                        to={item.path}
                        onClick={toggleMenu}
                        className={({isActive}) =>
                            `w-full  block p-2 my-2 text-[22px] text-center transition-all ease-in-out ${isActive ? "font-bold bg-teal-300 rounded-[10px] max-w-[450px]" : ""}`
                        }
                    >
                        {t(item.title)}
                    </NavLink>
                ))}
                <div className={"fixed bottom-5"}>
                    <button
                        className="mb-4"
                        onClick={toggleMenu}
                        aria-label={t("header.toggleSideMenu")}
                    >
                        <IoClose size={30} />
                    </button>
                </div>
            </div>
        </div>
    </header>
  );
};

export default Header;
