import React from "react";
import Header from "./header";
import Footer from "./footer";
import { CustomLayoutProps } from "../../interfaces/customLayout";
import ScrollToTop from "../../router/scrollToTop";
import { ToastContainer } from "react-toastify";
import Loader from "../../connected-components/loader";
import Contact from "./contact";
import { FaArrowUp } from "react-icons/fa";
import useScrollPosition from "../../shared/hooks/scrollPosition"; // Adjust the path as necessary

const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
    const scrollPosition = useScrollPosition();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div className="relative">
            <ScrollToTop />
            <ToastContainer
                position={"bottom-left"}
                toastStyle={{
                    borderRadius: 12,
                    background: "rgba(243,243,243,0.2)",
                    backdropFilter: "blur( 20px )",
                    color: "#fff",
                    border: "1px solid rgba( 255, 255, 255, 0.18 )",
                }}
            />
            <Header />
            {children}
            <Contact />
            <Footer />
            <Loader />
            {scrollPosition.y > 300 && !scrollPosition.isBottom && (
                <button
                    onClick={scrollToTop}
                    className={`fixed bottom-5 right-5 bg-black-900 text-white rounded-full p-3 flex items-center justify-center transition-all duration-500 animate-fadeInUp`}
                    style={{
                        animation: "fadeInUp 0.5s ease-in-out",
                    }}
                >
                    <FaArrowUp className="mr-2" />
                    Back to Top
                </button>
            )}
        </div>
    );
};

export default CustomLayout;
