import { ContactForm } from "../../../interfaces/components/contact";
import config from "../../../config";
import { store } from "../../../redux/store";
import {
  hideLoading,
  showLoading,
} from "../../../connected-components/loader/reducer";
import { generateFormData } from "../../../shared/utils/formHelper";
import { services } from "../../../api";

/**
 * Sends contact form data to the server.
 * @param {ContactForm} form - The contact form data.
 * @returns {Promise<boolean>} - A promise resolving to true if the form is successfully sent, false otherwise.
 */
export const sendContact = async (form: ContactForm) => {
  try {
    store.dispatch(showLoading());
    const formData = generateFormData(form);
    const res = await services.contact.submit(formData);
    if (res.result !== "success") {
      throw new Error("error");
    }
    return true;
  } catch (e) {
    if (config.debug) console.log(e);
    return false;
  } finally {
    store.dispatch(hideLoading());
  }
};
