import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../../components/button";
import Checkbox from "../../../components/checkbox";
import { toastService } from "../../../shared/utils/toast";
import { handleChange, validateEmail } from "../../../shared/utils/formHelper";
import { sendContact } from "./actions";
import Image from "../../../components/image";
import Text from "../../../components/text";
import { ContactForm } from "../../../interfaces/components/contact";
import ContactBackground from "../../../assets/images/contact/background.webp";
import config from "../../../config";
import Heading from "../../../components/heading";

const mandatoryFields: (keyof ContactForm)[] = [
  "first_name",
  "last_name",
  "recaptcha",
  "email",
];

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [form, setForm] = useState<ContactForm>({
    first_name: "",
    last_name: "",
    email: "",
    subscribe_updates: false,
    recaptcha: false,
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleSubmit = async () => {
    setSubmitted(true);
    const missingFields = mandatoryFields.filter((field) => !form[field]);
    if (missingFields.length === 0) {
      if (!validateEmail(form.email)) {
        toastService.showErrorToast("Please enter a valid email address!");
        return;
      }
      const temp = { ...form };
      temp.subscribe_updates = temp.subscribe_updates ? "1" : "0";
      const res = await sendContact(temp);
      if (!res) return;
      setForm({
        first_name: "",
        last_name: "",
        email: "",
        subscribe_updates: false,
        recaptcha: false,
      });
      recaptchaRef.current?.reset();
      toastService.showSuccessToast(t("contact.submitted"));
      setSubmitted(false);
    } else {
      toastService.showErrorToast(t("app.requiredFieldsError"));
    }
  };

  const onChange = (token: string | null) => {
    handleChange("recaptcha", !!token, form, setForm);
  };

  return (
    <section
      id="contact"
      className="flex justify-center self-stretch bg-teal-300 px-14 py-[98px] md:p-5 ease-in-out duration-300"
    >
      <div className="mb-[60px] flex w-[67%] flex-col items-center gap-12 rounded-[10px] border border-solid bg-gradient2 p-[41px] md:w-full md:p-5">
        <Image
          src={ContactBackground}
          alt="contact background"
          className="mx-auto h-[383px] w-full max-w-[1103px] object-cover md:h-auto"
        />
        <div className="mx-auto mb-2.5 flex w-full max-w-[1106px] self-stretch">
          <div className="flex w-full items-start justify-between gap-5 md:flex-col">
            <div className="flex w-[50%] flex-col items-start gap-[29px] md:w-full">
              <Heading
                as="h2"
                className="text-[40px] font-bold md:text-[34px] sm:text-2xl"
              >
                {t("contact.title")}
              </Heading>
              <Text
                size="md"
                as="p"
                className="w-[67%] text-lg font-light leading-5 md:w-full"
              >
                {t("contact.description")}
              </Text>
            </div>
            <div className="flex w-[42%] flex-col items-center gap-[22px] md:w-full">
              <div className="flex flex-col gap-[12px] self-stretch">
                <div className="flex gap-[12px] sm:flex-col">
                  <div className="flex w-full flex-col items-start gap-2 sm:w-full">
                    <Text as="p" className="text-base font-light">
                      {t("contact.firstName")}
                    </Text>
                    <input
                      name="first_name"
                      value={form.first_name}
                      onChange={(e) =>
                        handleChange(
                          "first_name",
                          e.target.value,
                          form,
                          setForm,
                        )
                      }
                      className={`p-2 h-[32px] rounded-[3px] bg-white-A700_19 px-5 shadow-bs border-[1px] border-solid ease-in-out duration-300  ${submitted && !form.first_name ? "border-red-400 bg-red-200" : "border-transparent"}`}
                    />
                  </div>
                  <div className="flex w-full flex-col items-start gap-2 sm:w-full">
                    <Text as="p" className="text-base font-light">
                      {t("contact.lastName")}
                    </Text>
                    <input
                      name="last_name"
                      value={form.last_name}
                      onChange={(e) =>
                        handleChange("last_name", e.target.value, form, setForm)
                      }
                      className={`p-2 h-[32px] rounded-[3px] bg-white-A700_19 px-5 shadow-bs border-[1px] border-solid ease-in-out duration-300 ${submitted && !form.last_name ? "border-red-400 bg-red-200" : "border-transparent"}`}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start gap-2">
                  <Text as="p" className="text-base font-light">
                    {t("contact.email")}
                  </Text>
                  <input
                    name="email"
                    type="email"
                    value={form.email}
                    onChange={(e) =>
                      handleChange("email", e.target.value, form, setForm)
                    }
                    className={`p-2 h-[32px] w-100 rounded-[3px] bg-white-A700_19 px-5 shadow-bs border-[1px] border-solid ease-in-out duration-300 ${submitted && !form.email ? "border-red-400 bg-red-200" : "border-transparent"}`}
                  />
                </div>
                <Checkbox
                  onValueChange={(val: boolean) =>
                    handleChange("subscribe_updates", val, form, setForm)
                  }
                  value={!!form.subscribe_updates}
                  label={t("contact.subscribe")}
                  className="flex gap-[11px] p-px text-sm font-light text-white-A700"
                />
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config.recaptchaKey}
                onChange={onChange}
              />
              <div className="flex h-[60px] min-w-[160px] flex-row items-center justify-center">
                <Button
                  label={t("contact.send")}
                  onClick={handleSubmit}
                  className={
                    "rounded-[10px] bg-gradient px-[35px] text-center text-lg font-bold text-white-A700 shadow-lg sm:px-5"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
