import { SideMenuItem } from "../interfaces/config/sideMenu";

const sideMenuDesktop: SideMenuItem[] = [
  {
    title: "menu.ourStory",
    path: "/our-story",
  },
  {
    title: "menu.oasisPlatform",
    path: "/oasis-platform",
  },
  {
    title: "menu.servingYourSuccess",
    path: "/serving-your-success",
  },
  {
    title: "menu.generalCompanyInfo",
    path: "/general-company-info",
  },
];

/**
 * The array of side menu items for the application.
 * @type {SideMenuItem[]}
 */
export const sideMenu: SideMenuItem[] = [
  {
    title: "menu.home",
    path: "/",
  },
  ...sideMenuDesktop,
];

export const desktopSideMenu = sideMenuDesktop;
