import React from "react";
import Text from "../../../../components/text";
import {
  currentFocusList,
  recommendedFocusList,
} from "../../../../shared/static/oasisPlatform";
import Image from "../../../../components/image";

const ComparisonList = () => {
  const renderList = (title: string, list: any[], width = "80%") => {
    return (
      <div className={"w-[40%] md:w-[100%]"}>
        <Text
          className="text-[32px] font-normal !text-black-900_01 md:text-[28px] sm:text-[18px] mb-[35px]"
          as={"p"}
          size={"xxl"}
        >
          {title}
        </Text>
        <div className={`flex justify-start flex-wrap w-[${width}]`}>
          {list.map((item, idx) => (
            <div key={idx} className={"w-[50%] flex flex-col items-center justify-start"}>
              <Image
                className={"w-[60px] h-[60px] object-contain"}
                key={idx}
                src={item.icon}
                alt={item.label}
              />
              <Text
                as={"p"}
                size={"xs"}
                className={
                  "text-black-900_01 whitespace-pre-wrap text-center mt-[12px] min-h-[80px]"
                }
              >
                {item.label}
              </Text>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={"flex justify-between flex-wrap"}>
      {renderList("What they are working on", currentFocusList)}
      {renderList("What should the be working on", recommendedFocusList, "90%")}
    </div>
  );
};

export default ComparisonList;
