import React from "react";
import Heading from "../../../../components/heading";
import Text from "../../../../components/text";
import styles from "./problem.module.scss";
import { useTranslation } from "react-i18next";

const Problem: React.FC = () => {
    const { t } = useTranslation();

    const tableData = [
        [
            t("home.problem.knowledge"),
            t("home.problem.restrictedAccess"),
            t("home.problem.tooMuchAccess"),
        ],
        [
            t("home.problem.resources"),
            t("home.problem.limitedResources"),
            t("home.problem.largeTeams"),
        ],
        [
            t("home.problem.environment"),
            t("home.problem.workingToSurvive"),
            t("home.problem.complexPolitical"),
        ],
        [
            t("home.problem.innovation"),
            t("home.problem.expensiveNotFit"),
            t("home.problem.bottleneckedRestrictions"),
        ],
    ];

    const tableHeading = [
        "",
        t("home.problem.smallBusiness"),
        t("home.problem.bigBusiness"),
    ];

    return (
        <div className={styles["problem-container"]}>
            <Heading
                as="h4"
                className="mt-[186px] text-[34px] font-bold md:text-[32px] sm:text-3xl"
            >
                {t("home.problem.heading")}
            </Heading>
            <Text
                size="xxl"
                as="p"
                className="mt-[23px] text-xl font-normal md:text-[22px]"
            >
                {t("home.problem.businessConstraints")}
            </Text>
            <div className="overflow-x-auto w-full">
                <table className="relative mt-[30px] w-full text-left text-sm rounded-[15px] max-w-full">
                    <thead className={`relative ${styles["heading"]}`}>
                    <tr>
                        {tableHeading.map((item, idx) => (
                            <th
                                key={idx}
                                scope="col"
                                className="px-6 py-4 text-center text-[28px] md:text-[22px] sm:text-[14px] capitalize"
                            >
                                {item}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((item, idx) => (
                        <tr key={idx} className="mt-[12px] relative">
                            {item.map((val, valIdx) => (
                                <td
                                    key={val}
                                    className={`px-6 py-4 ${
                                        valIdx > 0
                                            ? "text-center text-[16px] md:text-[14px]"
                                            : "font-bold text-[28px] md:text-[22px] sm:text-[16px]"
                                    }`}
                                >
                                    {val}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <Text
                size="xxl"
                as="p"
                className="mt-[75px] text-[22px] font-normal leading-9 md:w-full md:text-[18px]"
            >
                {t("home.problem.description")}
            </Text>
        </div>
    );
};

export default Problem;
