import React from "react";
import styles from "./coreServices.module.scss";
import Text from "../../../../components/text";
import Image from "../../../../components/image";
import OasisPlatform from "../../../../assets/images/shared/oasis-platform.webp";

const CoreServices = () => {
  return (
    <div
      className={`${styles["coreServices-container"]} py-[63px] w-[62vw] md:w-full mt-[80px] flex flex-col items-center`}
    >
      <Text
        size="xxl"
        as="p"
        className="pb-[63px] text-center text-white-A700 text-[28px] font-normal md:text-[26px] sm:text-2xl"
      >
        We provide OASIS™ through two core services
      </Text>
      <div
        className={`box-border w-[80%]  bg-white bg-opacity-25 rounded-[15px]  py-[16px] px-[12px] ${styles["custom-gradient-box"]}`}
      >
        <Text
          size="xxl"
          as="p"
          className="text-center text-white-A700 text-[20px] font-normal md:text-[18px] sm:text-[16px]"
        >
          OASIS™ Strategy to Execution Bridge – we guide and place your
          business on a pathway to success
        </Text>
      </div>
      <Image
        src={OasisPlatform}
        className={"w-[60%] md:w-full mt-[4%] md:my-[12%] object-contain"}
      />
      <div
        className={`box-border w-[80%]  bg-white bg-opacity-25 rounded-[15px]  py-[16px] px-[12px] ${styles["custom-gradient-box"]}`}
      >
        <Text
          size="xxl"
          as="p"
          className="text-center text-white-A700 text-[20px] font-normal md:text-[18px] sm:text-[16px]"
        >
          OASIS™ Platform – provided to your business to keep you on the
          pathway to achieve your goals
        </Text>
      </div>
    </div>
  );
};

export default CoreServices;
