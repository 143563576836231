import React, { useRef, useEffect, useState } from "react";
import Heading from "../../../../components/heading";
import Text from "../../../../components/text";
import styles from "./why.module.scss";
import { useTranslation } from "react-i18next";

interface DataItem {
  title: string;
  description: string[];
  width: string;
}

const data: DataItem[] = [
  {
    title: "home.why.alignment.title",
    description: [
      "home.why.alignment.desc1",
      "home.why.alignment.desc2",
      "home.why.alignment.desc3",
    ],
    width: "w-[45%]",
  },
  {
    title: "home.why.execution.title",
    description: [
      "home.why.execution.desc1",
      "home.why.execution.desc2",
      "home.why.execution.desc3",
    ],
    width: "w-[45%]",
  },
  {
    title: "home.why.forecast.title",
    description: [
      "home.why.forecast.desc1",
      "home.why.forecast.desc2",
      "home.why.forecast.desc3",
    ],
    width: "w-[35%]",
  },
  {
    title: "home.why.resourcing.title",
    description: [
      "home.why.resourcing.desc1",
      "home.why.resourcing.desc2",
      "home.why.resourcing.desc3",
    ],
    width: "w-[35%]",
  },
  {
    title: "home.why.adaptability.title",
    description: [
      "home.why.adaptability.desc1",
      "home.why.adaptability.desc2",
      "home.why.adaptability.desc3",
    ],
    width: "w-[45%]",
  },
  {
    title: "home.why.visibility.title",
    description: [
      "home.why.visibility.desc1",
      "home.why.visibility.desc2",
      "home.why.visibility.desc3",
    ],
    width: "w-[45%]",
  },
  {
    title: "home.why.communication.title",
    description: [
      "home.why.communication.desc1",
      "home.why.communication.desc2",
      "home.why.communication.desc3",
    ],
    width: "w-[45%]",
  },
  {
    title: "home.why.accountability.title",
    description: [
      "home.why.accountability.desc1",
      "home.why.accountability.desc2",
      "home.why.accountability.desc3",
    ],
    width: "w-[45%]",
  },
];

const Why: React.FC = () => {
  const { t } = useTranslation();
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [visibleItems, setVisibleItems] = useState<boolean[]>(Array(data.length).fill(false));

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = refs.current.indexOf(entry.target as HTMLDivElement);
          if (index !== -1) {
            setVisibleItems((prev) => {
              const newVisibleItems = [...prev];
              newVisibleItems[index] = true;
              return newVisibleItems;
            });
          }
        }
      });
    };

    const observerOptions = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentRefs = refs.current;

    currentRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
      <div
          className={`flex justify-center items-center self-stretch bg-teal-300 pr-[20px] md:px-[20px] py-[103px] md:p-5 relative ${styles["why-container"]}`}
      >
        <div className="flex justify-between w-[64%] md:w-full flex-wrap relative">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[75%] flex justify-center items-center pointer-events-none md:hidden">
            <div className="relative">
              <div
                  className={`${styles["custom-gradient-box"]} w-[500px] h-[500px] bg-white rounded-full flex justify-center items-center shadow-lg z-10`}
              >
              <span className="text-white-A700 font-bold xl:text-[60px] text-[40px]">
                Why?
              </span>
              </div>
            </div>
          </div>
          {data.map((item, idx) => (
              <div
                  key={idx}
                  ref={(el) => (refs.current[idx] = el)}
                  className={`relative rounded-[15px] bg-white-A700 p-4 shadow-md ${item.width} md:w-full m-2 ${
                      visibleItems[idx] ? 'animate-fadeInUp' : "opacity-0"
                  }`}
              >
                <Heading
                    as="h6"
                    className="text-lg font-bold leading-5 !text-teal-300 mb-4"
                >
                  {idx + 1}. {t(item.title)}
                </Heading>
                <Text as={"div"} className={"font-normal text-teal-300 w-full"}>
                  <ul className="list-disc pl-5">
                    {item.description.map((desc, index) => (
                        <li key={index} className="mb-2">
                          {t(desc)}
                        </li>
                    ))}
                  </ul>
                </Text>
              </div>
          ))}
        </div>
      </div>
  );
};

export default Why;
