/**
 * Configuration options for Flowforge application.
 * @typedef {Object} Config
 * @property {boolean} debug - Indicates whether debug mode is enabled.
 * @property {string} email - The email address for contacting Flowforge.
 * @property {string} phoneNumber - The primary phone number for contacting Flowforge.
 * @property {string} phoneNumber2 - The secondary phone number for contacting Flowforge.
 * @property {string} address - The address of Flowforge's location.
 */

/**
 * Configuration options for the Flowforge application.
 * @type {Config}
 */
const config = {
  debug: true,
  email: "info@flowforge.com.au",
  phoneNumber: "0402 129 100",
  phoneNumber2: "0449 983 033",
  phoneNumber3: "0416 374 379",
  address: "Melbourne, Australia",
  addressLong: "Melbourne, Victoria, Australia",
  recaptchaKey: "6LflRpwpAAAAAFnny08HcG9pczyvsuXtkgdaWc0H",
  recaptchaSecret: "6LflRpwpAAAAAD4geDv6n1RZZKo2xVYCjNpAUklR",
  facebook: "https://facebook.com",
  linkedIn: "https://linkedin.com",
  youtube: "https://youtube.com",
  instagram: "https://instagram.com",
};

export default config;
