import React from "react";
import Text from "../text";
import Image from "../image";
import Oasis from "../../assets/images/oasisPlatform/oasis.webp";
import Login from "../../assets/images/oasisPlatform/login.webp";
import Strategy from "../../assets/images/oasisPlatform/strategy.svg";

const sections = [
  {
    id: 1,
    text: "We have embedded our methodology",
    imageSrc: Oasis,
    alt: "oasis strategy",
    width: "25%",
    textClass:
      "text-[28px] font-bold text-center min-h-[70px] whitespace-pre-wrap !text-white-A700 md:text-[24px] sm:text-[18px] mb-[35px] md:h-[auto] md:text-start",
    imageClass: "w-[100%] h-[416px] object-contain md:h-[300px]",
  },
  {
    id: 2,
    text: "Into the OASIS™ Platform",
    imageSrc: Login,
    alt: "oasis login",
    width: "40%",
    textClass:
      "text-[28px] text-center font-bold min-h-[70px] !text-white-A700 md:text-[28px] sm:text-[18px] mb-[35px] md:h-[auto] md:text-start",
    imageClass: "w-[100%] h-[416px] object-contain md:h-[auto]",
  },
  {
    id: 3,
    text: "To automate",
    imageSrc: Strategy,
    alt: "strategy",
    width: "35%",
    textClass:
      "text-[28px] font-bold min-h-[70px] !text-white-A700 md:text-[28px] sm:text-[18px] mb-[35px] md:h-[auto]",
    imageClass: "w-[100%] h-[416px] object-contain md:h-[auto]",
  },
];

const OasisSolution = () => {
  return (
    <div className="flex justify-between w-full md:flex-wrap">
      {sections.map((section) => (
        <div
          key={section.id}
          className={`w-[${section.width}] md:w-[100%] md:mb-[90px]`}
        >
          <Text className={section.textClass} as="p" size="xxl">
            {section.text}
          </Text>
          <Image
            src={section.imageSrc}
            alt={section.alt}
            className={section.imageClass}
          />
        </div>
      ))}
    </div>
  );
};

export default OasisSolution;
