import React from "react";
import generateMeta from "../../../shared/services/metadataService";
import metaData from "../../../config/metaData";
import Heading from "../../../components/heading";
import Text from "../../../components/text";
import Image from "../../../components/image";
import { useTranslation } from "react-i18next";

import excellence from "../../../assets/images/ourStory/excellence.webp";
import greatPlaceToWork from "../../../assets/images/ourStory/greatPlaceToWork.svg";
import mostInnovativeCompany from "../../../assets/images/ourStory/mostInovativeCompany.svg";
import pmInstitute from "../../../assets/images/ourStory/pmInstitute.svg";
import strategy from "../../../assets/images/ourStory/strategy.webp";

const OurStory = () => {
  const { t } = useTranslation();

  return (
    <div>
      {generateMeta({
        title: metaData.ourStory.title,
        description: metaData.ourStory.description,
      })}
      <div className="bg-white-A700 flex flex-col justify-center items-center">
        <div className="w-[67%] sm:w-[100%] md:w-[100%] flex flex-col items-center container-x md:p-5">
          <div className="flex flex-col items-center">
            <Heading
              as="h1"
              className="mt-[103px] mb-[80px] text-[40px] font-bold text-teal-300 md:text-[38px] sm:text-2xl sm:mb-[20px] sm:mt-[40px] "
            >
              {t("story.heading")}
            </Heading>
            <Text
              size="xxl"
              as="p"
              className="font-normal whitespace-pre-wrap leading-9 !text-teal-300 text-[29px] md:text-[24px] sm:text-[18px] mb-[40px]"
            >
              {t("story.paragraph")}
            </Text>
          </div>
          <Image
            src={strategy}
            alt="strategy"
            className="h-[778px] md:h-[auto] w-100 rounded-[15px] object-cover"
          />
          <Heading
            as="h2"
            className="mt-[102px] text-teal-300 self-start text-4xl font-bold md:text-[34px] sm:text-[24px]"
          >
            {t("story.awardsHeading")}
          </Heading>
          <div className="mt-[47px] mb-[80px] flex w-[100%] pr-[20%] items-center justify-between sm:pr-[0] sm:justify-center gap-5 md:w-full md:flex-col sm:gap-[20px]">
            <div className="flex w-[20%] flex-col items-start bg-red-A400 md:w-full">
              <Image
                src={pmInstitute}
                alt="pmInstitute"
                className="h-[78px] w-[100%]"
              />
            </div>
            <Image
              src={greatPlaceToWork}
              alt="greatPlaceToWork"
              className="h-[74px] w-[74px] md:w-[40vw] md:h-[40vw]"
            />
            <Image
              src={excellence}
              alt="excellence"
              className="h-[74px] w-[74px] object-cover md:w-[40vw] md:h-[40vw]"
            />
            <Image
              src={mostInnovativeCompany}
              alt="mostInnovativeCompany"
              className="h-[74px] w-[24%] md:w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
