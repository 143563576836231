import React from "react";
import generateMeta from "../../../shared/services/metadataService";
import metaData from "../../../config/metaData";
import Heading from "../../../components/heading";
import Text from "../../../components/text";
import { useTranslation } from "react-i18next";
import ComparisonList from "./comparisonList";
import OasisSolution from "../../../components/oasisSolution";
import Image from "../../../components/image";
import PcFront from "../../../assets/images/oasisPlatform/pcFront.webp";
import OasisPlatformImage from "../../../assets/images/shared/oasis-platform.webp";
import Dashboard from "../../../assets/images/oasisPlatform/dashboard.webp";
import Oasis from "../../../assets/images/oasisPlatform/oasis.webp";
import ContentSection from "./contentSection";

const OasisPlatform: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      {generateMeta({
        title: metaData.generalCompanyInformation.title,
        description: metaData.generalCompanyInformation.description,
      })}
      <div className="bg-white-A700 flex flex-col justify-center items-center">
        <div className="w-[60%] sm:w-[100%] md:w-[100%] flex flex-col items-center container-x md:p-5">
          <div className="flex flex-col">
            <Heading
              as="h1"
              className="mt-[103px] sm:mt-[40px] text-[40px] font-bold text-teal-300 md:text-[38px] sm:text-2xl whitespace-pre-wrap"
            >
              {t("oasisPlatform.heading")}
              <span className="text-xl font-normal italic text-teal-300">
                {t("oasisPlatform.author")}
              </span>
            </Heading>
            <Heading
              as="h1"
              className="mt-[83px] mb-[80px] sm:mt-[40px] text-[40px] font-bold text-teal-300 md:text-[38px] sm:text-2xl"
            >
              {t("oasisPlatform.despairMessage")}
            </Heading>
            <ComparisonList />
            <Heading
              as="h1"
              className="mt-[83px] mb-[80px] sm:mt-[40px] text-[40px] text-center font-bold text-teal-300 md:text-[38px] sm:text-2xl"
            >
              {t("oasisPlatform.paradigmShift")}
            </Heading>
          </div>
        </div>
        <div className="flex flex-col items-center self-stretch bg-teal-300 px-14 pt-[40px] md:p-5">
          <div className={"w-[80%] md:w-full"}>
            <Heading
              as="h1"
              className="mb-[80px] text-[40px] text-center font-bold text-white-A700 md:text-[38px] sm:text-4xl"
            >
              {t("oasisPlatform.ourSolution")}
            </Heading>
            <OasisSolution />
            <Text
              as="p"
              size={"xl"}
              className={"w-[100%] mt-[60px] mb-[20px] md:mt-[10px]"}
            >
              {t("oasisPlatform.introduction")}
            </Text>
            <ContentSection
              text={[t("oasisPlatform.interactiveModeling")]}
              boldText={t("oasisPlatform.digitalBusinessConsultant")}
              imageSrc={PcFront}
              imageAlt="strategy"
              imageFirst={false}
              imageWidth="50%"
            />
            <Text
              as="p"
              size="xl"
              className="w-[100%] mt-[60px] mb-[20px] md:mt-[10px]"
            >
              {t("oasisPlatform.streamlineJourney")}
            </Text>
            <ContentSection
              text={[t("oasisPlatform.interactiveModeling")]}
              boldText={t("oasisPlatform.digitalBusinessConsultant")}
              imageSrc={OasisPlatformImage}
              imageAlt="strategy"
              imageFirst={true}
              imageWidth="50%"
            />
            <Text
              as="p"
              size="xl"
              className="w-[100%] mt-[60px] mb-[20px] md:mt-[10px]"
            >
              {t("oasisPlatform.turn3Percent")}
            </Text>
            <ContentSection
              text={[t("oasisPlatform.linkWithOtherPlatforms")]}
              boldText={t("oasisPlatform.clearDashboards")}
              imageSrc={Dashboard}
              imageAlt="strategy"
              imageFirst={false}
              imageWidth="45%"
            />
            <div
              className={
                "mt-[103px] flex flex-col px-[24%] md:px-[0] justify-center items-center"
              }
            >
              <Text
                as="p"
                size="xxl"
                className="w-[100%] text-2xl md:text-xl mb-[80px] md:mt-[10px] text-center"
              >
                {t("oasisPlatform.noConsultants")}
              </Text>
              <Image
                src={Oasis}
                alt={"oasis"}
                className={"w-full px-[15%] md:px-0 object-contain"}
              />
              <Text
                as="p"
                size="xxl"
                className="w-[100%] text-2xl md:text-xl mt-[80px] md:mb-[30px] text-center"
              >
                {t("oasisPlatform.joinUs")}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OasisPlatform;
