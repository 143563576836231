import SalesAndMarketing from "../../assets/images/oasisPlatform/salesAndMarketing.svg";
import MarketingAndBranding from "../../assets/images/oasisPlatform/marketingAndBranding.svg";
import Finances from "../../assets/images/oasisPlatform/finances.svg";
import CommunityAndCorporate from "../../assets/images/oasisPlatform/communityAndCorporate.svg";
import CapabilityBuilding from "../../assets/images/oasisPlatform/capabilityBuilding.svg";
import Customers from "../../assets/images/oasisPlatform/customers.svg";
import Projects from "../../assets/images/oasisPlatform/projects.svg";
import StrategicPlanning from "../../assets/images/oasisPlatform/strateegicPlanning.svg";
import Innovation from "../../assets/images/oasisPlatform/innovation.svg";
import CustomerInsights from "../../assets/images/oasisPlatform/customerInsights.svg";
import TeamManagement from "../../assets/images/oasisPlatform/teamManagement.svg";
import BusinessOperation from "../../assets/images/oasisPlatform/businessOperation.svg";

export const currentFocusList = [
  {
    icon: SalesAndMarketing,
    label: "Sales and Marketing",
  },
  {
    icon: Customers,
    label: "Customers",
  },
  {
    icon: BusinessOperation,
    label: "Business Operations",
  },
  {
    icon: Finances,
    label: "Finances",
  },
  {
    icon: Projects,
    label: "Projects",
  },
  {
    icon: TeamManagement,
    label: "Team Management",
  },
];

export const recommendedFocusList = [
  {
    icon: StrategicPlanning,
    label: "Strategic Planning\nand Analysis",
  },
  {
    icon: CustomerInsights,
    label: "Customer Insights",
  },
  {
    icon: Innovation,
    label: "Innovation and\nImprovement",
  },
  {
    icon: MarketingAndBranding,
    label: "Marketing and Branding",
  },
  {
    icon: CapabilityBuilding,
    label: "Capability Building",
  },
  {
    icon: CommunityAndCorporate,
    label: "Community & Corporate\nSocial Responsibility",
  },
];
